import * as React from "react"
import { useLocation } from "@reach/router"
import { graphql } from "gatsby"
import { Link, Trans } from "gatsby-plugin-react-i18next"
import Seo from "../components/SEO"
import { GatsbyImage } from "gatsby-plugin-image"
import Share from "../components/ShareContainer"
import Banner from "../components/Banner"
import styled from "styled-components"
import Layout from "../components/Layout"
import { DateTime } from "luxon"

const BlogPostStyles = styled.div`
  .meta {
    h2 {
      font-size: var(--h4);
      color: var(--primaryColor);
      margin: calc(var(--spacing) / 2) 0;
    }
  }

  .post-img {
    margin: calc(var(--spacing) * 2) 0;

    @media (min-width: 1200px) {
      margin: calc(var(--spacing) * 4) 0;
    }
  }

  font-weight: 400;
  font-size: var(--h4);
  color: var(--primaryColor);

  a {
    text-decoration: none;
    margin-right: calc(var(--spacing) / 2);
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 0.1rem;
      width: 100%;
      background-color: var(--charcoal);
      left: 0;
      bottom: -0.25rem;
      opacity: 1;
      transition: opacity var(--transSlow);
    }

    &:hover,
    &:focus {
      cursor: pointer;

      &:after {
        opacity: 0.15;
      }
    }

    &::last-child {
      margin-right: 0;
    }
  }
`

export default function Template({ data }) {
	let location = useLocation()
	const {
		markdownRemark: { frontmatter, html },
	} = data // Object destructuring
	let featuredImgFluid =
		frontmatter.featuredImage ?
			frontmatter.featuredImage.childImageSharp.gatsbyImageData :
			null;

	return (
		<>
			<Seo title={frontmatter.title} description={frontmatter.description} />
			<Layout>
				<BlogPostStyles>
					<Banner content={frontmatter.title} />

					{/* Check if date or author has been declared in MD file
      If so, render the meta */}
					{(frontmatter.date || frontmatter.author) && (
						<aside className="meta">
							<h2>
								{/* If there is date, display it */}
								<Trans>Posted</Trans> {frontmatter.date && `/ ${DateTime.fromISO(frontmatter.date).toLocaleString()}`}{" "}
								{/* If there is author, display it */}
								{frontmatter.author && `/ ${frontmatter.author}`}
							</h2>
						</aside>
					)}

					{featuredImgFluid && (
						<div className="post-img">
							<GatsbyImage image={featuredImgFluid} alt={frontmatter.title} />
						</div>
					)}
					<div
						className="blog-post-content"
						dangerouslySetInnerHTML={{ __html: html }}
					/>
					<hr />
					<Link to="/posts" className="btn-link">
						<Trans>Return to Posts</Trans>
					</Link>

					{/* If there are tags for the post, render this section */}
					{frontmatter.tags && frontmatter.tags.length > 0 && (
						<>
							<hr />
							<h4>
								<Trans>Posted under</Trans> /{" "}
								{frontmatter.tags.map((tagName, index) => {
									return (
										<Link to={`/tags/${tagName}`} key={index}>
											{tagName}
										</Link>
									)
								})}
							</h4>
						</>
					)}
					<hr />
					<Share facebook twitter linkedin href={location.href} />
				</BlogPostStyles>
			</Layout>
		</>
	)
}

export const pageQuery = graphql`
	query ($id: String!, $locale: String!) {
		markdownRemark(id: { eq: $id }) {
			html
			frontmatter {
				date
				slug
				title
				tags
				description
				author
				featuredImage {
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
					}
				}
			}
		}

		locales: allLocale(filter: {language: {eq: $locale}}) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
